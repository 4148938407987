/* eslint-disable jsx-a11y/alt-text */

import { FiUser, FiHome } from "react-icons/fi";
import { FaUserFriends } from "react-icons/fa";
import { BiBuildings, BiCalendar } from "react-icons/bi";
import { BsListCheck } from "react-icons/bs";

import { Box, Flex, VStack, Image } from "@chakra-ui/react";

import { HiDocumentReport } from "react-icons/hi";
import { Can } from "../Can";

import { SideBarLink } from "./SideBarLink";

function SideBar() {
   return (
      <Flex bg="beige.200" px="0.5em" py="8" flexDir="column" align="center">
         <Image src="/logo-aguia-branco.png" width="60px" height="26px" />
         <Box mt="12" alignItems="center">
            <VStack spacing={3}>
               <SideBarLink src="/" icon={FiHome} title="Página inicial" />
               <Can roles={["Master", "Onboarding"]}>
                  <SideBarLink
                     src="/colaboradores"
                     icon={FiUser}
                     title="Colaboradores"
                  />
               </Can>
               <SideBarLink
                  src="/"
                  icon={FaUserFriends}
                  title="Segmento Compass"
               />
{/*                src="/compass" */}
               <SideBarLink src="/filiais" icon={BiBuildings} title="Filiais" />
               <Can roles={["Master", "Diagnostic"]}>
                  <SideBarLink
                     src="/diagnostico"
                     icon={BsListCheck}
                     title="Diagnóstico Financeiro"
                  />
               </Can>
               <SideBarLink src="/" icon={BiCalendar} title="Eventos" />
{/*                src="/eventos"  */}
               <Can roles={["Master", "Manager"]}>
                  <SideBarLink
                     openInNewWindow
                     src={process.env.NEXT_PUBLIC_POWER_BI_IFRAME_SRC}
                     icon={HiDocumentReport}
                     title="Relatórios"
                  />
               </Can>
            </VStack>
         </Box>
      </Flex>
   );
}

export { SideBar };
